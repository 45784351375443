import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import * as BS from "react-bootstrap"
import "bootstrap/dist/css/bootstrap-grid.css"

const IndexPage = (props) => {

  const renderIcons = (edge, index) => {

    if(edge.node.businessRef.icon !== 'nf' && edge.node.businessRef.icon !== 'none'){
  
      return (
       
        <tr className="table-striped tbody tr:nth-of-type(odd)" key={index}>
          <td><img style={{maxWidth: 20, marginBottom: 0}} src={`/images/business-icons/${edge.node.businessRef.icon}`} alt={`${edge.node.businessRef.icon} name`}></img> {edge.node.businessRef.name}</td>
          <td>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</td>
          <td className="d-none d-sm-table-cell">{edge.node.businessRef.what[0].toUpperCase() + edge.node.businessRef.what.substring(1)}</td>
          <td className="d-none d-sm-table-cell">{edge.node.businessRef.founders[0]}</td>
          <td className="d-none d-sm-table-cell">{edge.node.investors[0]}</td>
          <td className="d-none d-sm-table-cell">{edge.node.date.split('T')[0].split('-').reverse().join('-')}</td>
          <td className="d-none d-sm-table-cell">{edge.node.businessRef.locations[0].country}</td>
      </tr>
      )
    }
  }

  return (
    <section>
       <Helmet>
        <html lang="en" />
        <title>Sign Up To UnicornAlert</title>
        <meta
          name="title"
          content="Sign Up To UnicornAlert"
        ></meta>
        <meta
          name="description"
          content="Get the latest updates from startups, investors and fundraising in your realtime UnicornAlert dashboard."
        />
      </Helmet>
    
      <BS.Container style={{maxWidth: 1400}}>
    <BS.Row>
      
      <BS.Table striped bordered hover size="sm" style={{maxWidth: 1400, margin: '0px auto', marginBottom: -19, fontSize: 14 }}>
      <thead>
      <tr>
      <th>Company</th>
      <th>Funding (USD)</th>
      <th className="d-none d-sm-table-cell">Market</th>
      <th className="d-none d-sm-table-cell">Founder</th>
      <th className="d-none d-sm-table-cell">Investor</th>
      <th className="d-none d-sm-table-cell">Funding Date</th>
      <th className="d-none d-sm-table-cell">Location</th>
      </tr>
      </thead>

      <tbody>
      {props.data.allFunding.edges.slice(0,15).map(renderIcons)}

      </tbody>
      </BS.Table>
      <hr className="hrr"></hr>
 
    </BS.Row>
      

      </BS.Container>
    </section>
  )

}



export const query = graphql`
  {
    allFunding(sort: { order: DESC, fields: date }, limit: 28) {
      edges {
        node {
          amount
          date
          investors
          currency
          id
          businessRef {
            name
            website
            what
            icon
            founders
            locations {
              city
              country
            }
          }
        }
      }
      totalCount
    }
  }
`

export default IndexPage
